'use client';
import React, { FC, HTMLAttributes } from 'react'
import Container from '../container'
import Image from '../image'
import styles from './FeefoFooter.module.css'
import { FeefoReviewsResponse, FeefoMerchantResponse } from '@/app/types/feefo-types'
import StarRating from '../star-rating'
import CustomLink from '../link'
import dayjs from 'dayjs'

type Props = {
  className?: string
  style?: React.CSSProperties
  feefoSummary: FeefoMerchantResponse | null
  feefoReviews: FeefoReviewsResponse | null
}

const FeefoFooterClient: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
  feefoSummary,
  feefoReviews,
  ...props
}) => {
  if (!feefoSummary?.rating?.rating || !feefoReviews?.reviews) {
    return null;
  }

  const rating = feefoSummary.rating.rating;
  const reviews = feefoReviews.reviews;

  return (
    <div className={styles.outterContainer}>
      <Container {...props}>
        <div className={styles.container}>
          <div className={styles.innerContent}>
            <div className={styles.title}>
              <span>Average Customer Rating:</span>
              <StarRating rating={rating} totalStars={5} />
              <span className={styles.rating}>
                <strong>{rating}</strong>/5
              </span>
              <span className={styles.divider}>|</span>
              <div className={styles.feefoLogo}>
                <Image
                  asset='https://a.storyblok.com/f/306333/300x73/c47609163e/feefo.svg'
                  alt="Feefo logo"
                />
              </div>
            </div>
            <p className={styles.foot}>
              <strong>Independent Service Rating</strong> based on{' '}
              <strong>{feefoSummary.rating.service?.count || 0}</strong> verified reviews.
              <CustomLink
                prefetch={false}
                target='_blank'
                href='https://www.feefo.com/en-GB/reviews/cruise-nation?timeFrame=YEAR'
              >
                Read all reviews
              </CustomLink>
            </p>
          </div>

          <div className={styles.award}>
            <Image
              asset="https://a.storyblok.com/f/306333/480x480/eb8f6c141c/7e713d7028bfa5765f20.svg"
              alt="Award"
            />
          </div>
        </div>

        <div className={styles.reviews}>
          {reviews
            .filter((review) => (review.service?.rating?.rating || 0) >= 4.5)
            .slice(0, 4)
            .map((review, key) => (
              <div className={styles.review} key={key}>
                <StarRating rating={review.service.rating.rating} totalStars={5} />
                <p className={styles.reviewTitle}>{review.service.title}</p>
                <p className={styles.reviewText}>{review.service.review}</p>
                <p className={styles.reviewDetails}>
                  <span className={styles.customerName}>{review.customer?.display_name}</span>
                  -
                  <span className={styles.date}>
                    {dayjs(review.service.created_at).format('DD MMMM YYYY')}
                  </span>
                </p>
              </div>
            ))}
        </div>
      </Container>
    </div>
  )
}

export default FeefoFooterClient