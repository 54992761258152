"use client"
import { SecondFooterSectionStoryblok } from '@/app/types/component-types-sb'
import styles from '../Footer.module.css';
import React from 'react'
import Container from '@/app/components/globals/container';
import FooterGridItem from './FooterGridItem';
import { FooterLinkColumnStoryblok } from '../../../../types/component-types-sb';

type Props = {
  content: SecondFooterSectionStoryblok
}

const SecondFooterSection = ({content}: Props) => {
  return (
    <div className={styles.secondFooter}>
      <Container>
      <div
        className={styles.secondFooterContainer}
      >
        <div className={styles.footerGrid}>
          {content.footer_link_columns?.map((item: FooterLinkColumnStoryblok) => (
            <FooterGridItem key={item._uid} item={item}/>
          ))}
        </div>
      </div>
      </Container>
    </div>
  )
}

export default SecondFooterSection